import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FeedbackForm } from 'types/CustomerFeedback';
import { Button } from 'ui/Button/Button';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'ui/Form/Form';
import { Textarea } from 'ui/TextArea/TextArea';
import { z } from 'zod';

const useSchema = () => {
  const { formatMessage } = useIntl();

  return z.object({
    remarks: z
      .string()
      .min(1, {
        message: formatMessage({ id: 'form_validation_required' }),
      })
      .max(1000, { message: formatMessage({ id: 'form_validation_max' }, { max: 1000 }) }),
  });
};

type FormValues = z.infer<ReturnType<typeof useSchema>>;

interface CustomerFeedbackFormProps {
  onSubmit: (values: FeedbackForm) => void;
}

const CustomerFeedbackForm = ({ onSubmit }: CustomerFeedbackFormProps) => {
  const { formatMessage } = useIntl();

  const formSchema = useSchema();

  const form = useForm<FormValues>({
    defaultValues: {
      remarks: '',
    },
    resolver: zodResolver(formSchema),
  });

  return (
    <Form {...form}>
      <form className="space-y-2" onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="remarks"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {formatMessage({
                  id: 'general_customer_feedback_modal_remarks',
                })}
              </FormLabel>
              <FormControl>
                <Textarea {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button className="w-full" disabled={!form.formState.isDirty || form.formState.isSubmitting} type="submit">
          {formatMessage({
            id: 'general_customer_feedback_modal_send_btn',
          })}
        </Button>
      </form>
    </Form>
  );
};

export default CustomerFeedbackForm;
