import * as React from 'react';
import { cn } from 'utils/cn';

const Textarea = React.forwardRef<HTMLTextAreaElement, React.ComponentProps<'textarea'>>(
  ({ className, ...props }, ref) => (
    <textarea
      className={cn(
        'placeholder:text-accent-6- flex min-h-[80px] w-full rounded-md border border-accent-40 bg-transparent px-3 py-2 text-base shadow-sm focus:border-accent-40 focus:ring-0 disabled:cursor-not-allowed disabled:border-accent-60 disabled:text-accent-60',

        className,
      )}
      ref={ref}
      {...props}
    />
  ),
);
Textarea.displayName = 'Textarea';

export { Textarea };
