import { ReactNode } from 'react';
import { StyledCheckList, StyledCheckListProps } from './CheckList.styled';

interface CheckListProps extends StyledCheckListProps {
  children: ReactNode;
  className?: string;
}
const CheckList = ({ children, ...props }: CheckListProps) => <StyledCheckList {...props}>{children}</StyledCheckList>;

export default CheckList;
