import RatingStars from '../../RatingStars/RatingStars';

interface RatingOverviewItemProps {
  editable?: boolean;
  rating?: number;
  setRatingValues?: (selectedRating: number, type?: string) => void;
  title: string;
  type?: string;
}

const RatingOverviewItem = ({ editable, rating, setRatingValues, title, type }: RatingOverviewItemProps) => (
  <li className="rating-cat">
    <div className="title">{title}</div>
    <div className="rating-wrap rating-small">
      {editable ? (
        <RatingStars currentRating={rating} editable setRatingValues={setRatingValues} title={title} type={type} />
      ) : (
        <RatingStars currentRating={rating} title={title} />
      )}
    </div>
  </li>
);

export default RatingOverviewItem;
